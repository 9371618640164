// eslint-disable-next-line import/no-unresolved, import/extensions
import Minor from '@/assets/icons/home/Minor.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import Moderate from '@/assets/icons/home/Moderate.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import critical from '@/assets/icons/home/critical.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import Monitoring from '@/assets/icons/home/Monitoring.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import Inspected from '@/assets/icons/home/Inspected.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import { parseTime, formatTime } from '@/utils'
import router from '@/router'
import common from '@/common'
import InspectedM from '../../../assets/Inspected Tower_M.png'
import MonitoringM from '../../../assets/Monitoring Tower_M.png'

export const listViewHeaders = [
  {
    label: 'Tower ID',
    prop: 'id',
    property: {
      sortable: true
    },
    render: (h, { row }) => {
      return h('div', { class: '_flex _flex-align__center' }, [
        h('el-image', {
          style: 'width:  50px; height: 50px; border-radius: 5px; margin-right: 10px',
          props: {
            fit: 'cover',
            src: row.imgUrl,
            previewSrcList: [row.imgUrl]
          }
        }),
        row.code
      ])
    }
  },
  {
    label: 'Project Name',
    prop: 'name',
    property: {
      sortable: true
    }
  },
  {
    label: 'Category',
    prop: 'catagories',
    property: {
      width: '300px',
      sortable: true
    },
    render: (h, { row }) => {
      const imgVNodes = []
      if ([0, 2].includes(row.catagories)) {
        imgVNodes.push(h('img', { style: 'margin-right: 10px', attrs: { src: Inspected } }))
        imgVNodes.push('Inspection')
      }
      if ([1, 2].includes(row.catagories)) {
        imgVNodes.push(
          h('img', {
            style: imgVNodes.length > 0 ? 'margin: 0 10px' : 'margin-right: 10px',
            attrs: { src: Monitoring }
          })
        )
        imgVNodes.push('Sensor Monitoring')
      }

      return h('div', { class: '_flex _flex-align__center' }, imgVNodes)
    }
  },
  {
    label: 'Location',
    prop: 'location'
  },
  {
    label: 'Created',
    prop: 'createdTime',
    property: {
      sortable: true
    },
    formatter: (row) => `${parseTime(row.createdTime, 'reg')}`
  },
  {
    label: 'Last Modified',
    prop: 'updateTime',
    property: {
      sortable: true
    },
    formatter: (row) => `${formatTime(Number(row.updateTime))}`
  }
]

export const alertHistoryHeader = [
  {
    label: 'Severity',
    prop: 'severity',
    property: {
      sortable: 'custom'
    },
    render: (h, params) => {
      let imgSrc = ''
      switch (params.row.severity) {
        case 'major':
        case 'Major':
          imgSrc = critical
          break
        case 'moderate':
        case 'Moderate':
          imgSrc = Moderate
          break
        case 'minor':
        case 'Minor':
          imgSrc = Minor
          break
        default:
      }

      return h('div', { class: '_flex _flex-align__center' }, [
        h('img', { attrs: { src: imgSrc }, style: { marginRight: '10px' } }),
        h('span', params.row.severity)
      ])
    }
  },
  {
    label: 'Tower ID',
    prop: 'towerCode',
    property: {
      sortable: 'custom'
    }
  },
  {
    label: 'Defect ID',
    prop: 'defectId',
    property: {
      sortable: 'custom'
    }
  },
  {
    label: 'Category',
    prop: 'defectType',
    property: {
      sortable: 'custom'
    }
  },
  {
    label: 'Created',
    prop: 'createdTime',
    property: {
      sortable: 'custom'
    },
    formatter: (row) => parseTime(row.createdTime, 'reg')
  }
]

export const alertHistoryOperate = {
  width: 150,
  list: [
    {
      type: 'info',
      label: 'Show More',
      show: true,
      click: (row) => {
        router.push({
          path: '/analysis',
          query: {
            id: row.id,
            // 0 跳转详情
            type: '0'
          }
        })
      }
    }
  ]
}

// 创建marker
export const createMarker = (map, google, data, cb) => {
  const marker = new google.maps.Marker({
    position: { lat: Number(data.lat) || 22.379141, lng: Number(data.lng) || 114.17407 },
    map,
    label: {
      text: data.code,
      color: '#FFFFFF',
      fontSize: '10px',
      fontFamily: 'Open Sans',
      className: 'marker-label'
    },
    icon: {
      url: data.code === common.code ? MonitoringM : InspectedM,
      size: new google.maps.Size(54, 54),
      labelOrigin: new google.maps.Point(25, 70)
    }
  })
  // eslint-disable-next-line func-names
  marker.addListener('click', function () {
    cb(data)
  })
  return marker
}
