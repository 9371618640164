<template>
  <div class="map-view">
    <div id="map"></div>
    <img :src="mapBottom" alt="" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { Loader } from '@googlemaps/js-api-loader'
import location from '@/assets/icons/location.png'
import mapBottom from '@/assets/map-bottom.png'
import { createMarker } from './index-data'

const loader = new Loader({
  apiKey: 'AIzaSyC-cNH9r6-yiEv71R4_wrHWs4VF-jfZyqU',
  version: 'weekly',
  libraries: ['places']
})
const mapOptions = {
  center: {
    lat: 22.356935,
    lng: 114.216105
  },
  zoom: 19
}

let map
// 存储 marker 便于删除
// eslint-disable-next-line no-unused-vars
let markers
let google
// 当前选中的 marker
let currentMarker
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      mapBottom,
      iRender: false
    }
  },
  mounted() {
    loader.load().then((g) => {
      google = g
      map = new google.maps.Map(document.getElementById('map'), mapOptions)
      if (this.list.length && !markers) {
        this.renderTower(this.list, 'watch')
      }
    })
  },
  watch: {
    list: {
      handler(val) {
        if (!map || !val || !val.length) return
        this.renderTower(val, 'watch')
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    renderTower(val) {
      if (markers && markers.length > 0) return
      // 红色覆盖物
      const cb = (data) => {
        if (currentMarker) {
          currentMarker.setPosition({ lat: Number(data.lat), lng: Number(data.lng) })
        } else {
          currentMarker = new google.maps.Marker({
            position: { lat: Number(data.lat), lng: Number(data.lng) },
            map,
            icon: {
              url: location,
              anchor: new google.maps.Point(30, 110)
            }
          })
        }

        this.$emit('tower-change', data)
      }

      markers = val.map((item) => createMarker(map, google, item, cb))
    },
    setVisible(val) {
      markers.forEach((item) => {
        if (val === '' || item.label.text.indexOf(val) !== -1) {
          item.setVisible(true)
          if (currentMarker) currentMarker.setPosition(item.getPosition())
        } else item.setVisible(false)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.map-view {
  height: calc(100vh - 190px - var(--main-padding) - var(--header-height));
  width: calc(100vw - var(--menu-width)) !important;
  position: absolute !important;
  transform: translateX(-50px);
  transition: all 0.3s ease;
  img {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 288px;
    height: 66px;
  }

  #map {
    width: 100%;
    height: 100%;
    // position: relative;

    ::v-deep .marker-label {
      background: rgba(0, 0, 0, 0.6);
      padding: 3px 20px;
      font-size: 10px;
      border-radius: 3px;
    }
  }
}
</style>
